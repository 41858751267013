import React from 'react';
import { SettingsInputAntennaOutlined,  WbIncandescentOutlined, SupervisorAccountOutlined } from '@material-ui/icons';
import Styles from './steps.module.scss';

export default function Steps() {
  return (
    <>
      <div className={Styles.container}>
        <div className='siteMaxWidth'>
        <div className={Styles.stepper}>
          <a className={Styles.step} href="/strategicintervention">
          <WbIncandescentOutlined />
            <span className={Styles.title}>STRATEGIC INTERVENTION COACHING</span>
            <span className={Styles.subTitle}>Moments of decision shape your destiny</span>
          </a>
          <a className={Styles.step} href="/businesscoaching">
          <SupervisorAccountOutlined />
            <span className={Styles.title}>BUSINESS COACHING</span>
            <span className={Styles.subTitle}>Manage things but lead people</span>
          </a>
          <a className={Styles.step} href="/eft">
          <SettingsInputAntennaOutlined />
            <span className={Styles.title}>E.F.T & MATRIX RE-INPRINTING</span>
            <span className={Styles.subTitle}>Remove fears, doubts and upsets</span>
          </a>
        </div>
        </div>
      </div>
     </>
  );
}