import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes} from 'react-router-dom';
import Header from './components/header';
import Navbar from './components/navBar';
import Footer from './components/footer';
import Sasi from './components/sasi/sasi';
import About from './components/aboutSarah/aboutSarah';
import PersonalMindset from './components/personalMindset/personalMindset';
import UltimateRelationship from './components/ultimateRelationship/ultimateRelationship';
import StrategicIntervention from './components/strategicIntervention/strategicIntervention';
import RelationshipCounselling from './components/relationshipCounselling/relationshipCounselling';
import Eft from './components/eft/eft';
import Matrix from './components/matrix/matrix';
import ConcentratedPrograms from './components/concentratedPrograms/concentratedPrograms';
import BusinessCoaching from './components/businessCoaching/businessCoaching';
import PublicSpeaking from './components/publicSpeaking/publicSpeaking';
import ContactUs from './components/contactUs/contactUs';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import './App.scss';

function App() {

  const [ navShowing, setNavShowing ] = useState(false);
  const [ isSticky, setSticky ] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < -130);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const showNav = () => {
    setNavShowing(!navShowing);
  }

  return (
      <div className={`siteContainer ${isSticky ? 'stickNav' : ''}`} ref={ref}>
      <Header showNav={showNav}/>
      <Navbar navShowing={navShowing} showNav={showNav}/>
      <main>
        <Routes>
          <Route path="/" element={<Sasi/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/personalmindset" element={<PersonalMindset/>} />
          <Route exact path="/ultimaterelationship" element={<UltimateRelationship/>} />
          <Route exact path="/strategicintervention" element={<StrategicIntervention/>} />
          <Route exact path="/relationshipcounselling" element={<RelationshipCounselling/>} />
          <Route exact path="/eft" element={<Eft/>} />
          <Route exact path="/matrix" element={<Matrix/>} />
          <Route exact path="/concentratedprograms" element={<ConcentratedPrograms/>} />
          <Route exact path="/businesscoaching" element={<BusinessCoaching/>} />
          <Route exact path="/publicspeaking" element={<PublicSpeaking/>} />
          <Route exact path="/contactus" element={<ContactUs/>} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy/>} />
          </Routes>
      </main>
      <Footer />
      </div>
  );
}

export default App;
