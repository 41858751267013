import React from 'react';
import face from '../../assets/images/EFT_Face.png';
import body from '../../assets/images/EFT_Body.png';
import Banner from './banner';
import styles from "./eft.module.scss";

export default function Eft() {
  return (
    <div>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>E.F.T</h1>
        </div>
      </div>
      <Banner></Banner>
      <div className={`siteMaxWidth pageContainer`}>
        <p><b>EFT</b>, or <b>‘Tapping’</b>, is a powerful tool for improving your life on many different levels: mental, emotional and physical.  It can be used to remove fears, doubts and upsets, and has been proven to effectively address a range of issues from anxiety, chronic pain, addiction, fear, weight control, stress relief, phobias, self-limiting beliefs, financial abundance, and so much more.  <strong>During your consultation, you will be taught how to 'tap' yourself, giving you a step-by-step guide   to overcoming any obstacle that is keeping you from success.  You can become your own therapist or counsellor. </strong></p>
        <p><img className={styles.face} src={face} alt="Face points" width="315" height="245" /></p>
        <p><strong>How Does It Work?</strong></p>
        <p>EFT or 'Tapping' is based on the principles of both ancient acupressure and modern psychology, EFT concentrates on tapping on specific meridian endpoints while focusing on the worries, fears, anxieties or physical sensations being experienced.</p>
        <p><img className={styles.body} src={body} alt="Body points" width="320" height="380" /></p>
        <p>The combination of talking through these negative thoughts and emotions, and tapping,   quickly calms the nervous system and restores the balance of energy in the body<b>.</b></p>
        <p><b>EFT or 'Tapping' has been scientifically proven to reduce Cortisol levels and other stress hormones by 24% within a one hour session.</b></p>
        <p>At the same time, it allows the brain to re-visit the thoughts and meanings given to the many situations that cause us stress, anxiety, fear of failure, fear of not being good enough, self-limiting beliefs, and physical illness and discomfort, to see if they are still relevant.</p>
        <p>99.9% of the time, they are no longer relevant, and quite often stem from childhood memories.  Once our subconscious mind recognizes where and when they were formed, it will very easily release the negative thoughts and symptoms and replace them with more truthful, positive life enhancing thoughts and beliefs.</p>
        <p>To enable these deep psychological changes, you have to work on the subconscious level, just thinking positively and consciously will not get to the root of the problem.  That is why EFT is so remarkable in its simplicity and effectiveness.</p>
      </div>
    </div>
  );
}