import React from 'react';
import Timeline from "../../assets/images/timeLine.png";
import AboutSarahImg from "../../assets/images/aboutSarah.jpg";
import Pod from "../../assets/images/SARAH ASHWORTH_028.jpg";
import styles from './aboutSarah.module.scss';

export default function AboutSarah() {
  return (
    <div>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>About Sarah</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer`}>
        <div className={styles.aboutSarahContainer}>
          <img className={styles.timeLine} src={Timeline} alt="timeline"></img>
          <div className="aboutSarah">
            <img className={styles.aboutSarahImg} src={AboutSarahImg} alt="Sarah"></img>
            <h3>Who is Sarah Ashworth?</h3>
            <div className={styles.textArea}>
              <p>Well, apart from my coaching qualifications I am a Certified Strategic Intervention Coach, Marriage Education and Divorce Prevention Councellor through The Robbins-Madanes Center For Strategic Intervention, AAMET Certified EFT Practitioner, AAMET Certified Matrix Re-imprinting Practitioner, Reiki Practitioner, Master Life Coach, Diploma in Business Coaching, and Diploma in Developmental Leadership, what makes me qualify as someone you can trust to help you take your life to the next level? Every experience I have had has made me the person I am today, and good or bad, I am grateful for them all! My journey up to now, has, like most people, been a bit of a roller coaster ride. It has taken me a very long time and a lot of books, courses, and qualifications to understand that it isn't the challenges you face in life, but the meaning you give those challenges. We are the hero/heroine of our own life story.</p>
              <h3>Snippets of My Epic Adventure Story!</h3>
              
              <p>Poor but Happy Childhood, Married at 21 after whirlwind holiday romance, beautiful daughter born when I was 24, handsome son born when I was 26, my children were my first experience of absolute unconditional love! Whirlwind romance turned into more of a monsoon, my husband became a functioning alcoholic, shortly followed by an un-functioning alcoholic, getting to the stage where my children's lives, and my own was in danger, so after 20 years of trying to make it work, we separated, and he walked away without a backward glance towards a life of his choosing - whiskey and loneliness. Although I was left with two traumatised children one 13 and one 15, and no form of support, lots of debt which only surfaced when he left, and the threat of homelessness, the meaning I gave to this was I was free! I was only responsible for my children! It wasn't an easy journey, but so rewarding! My Children flourished, as did my career! I went from part time telesales, to staff trainer both here and in USA, Regional Sales Manager, Corporate Accounts Manager, Business Development Director, Head of London Business responsible for a National Sales Team, and a Board Director! Not bad for a single mother of 2, with only 5 O levels and RSA in typing and shorthand! Instrumental in me achieving this was the many courses and books I experienced, I have had the honour and privilege of studying with some remarkable leaders in the field of psychology and human development, Karl Dawson, Dale Carnegie, Brian Tracey, Steven Covey, Anthony Robbins, Deepak Chopra, and Cloe Madanes to name but a few.</p>
              <p>
              <img className={styles.pod} src={Pod} alt="Secret Sanctuary"></img>
                Whilst pursuing my Corporate Career I studied with the Coaching Academy and became a life coach in 2004. I used the skills I gained to help my corporate clients and colleagues. It was so rewarding to be able to help people I dedicated more and more of my time until I finally left corporate life in 2012 to fully commit to my work in supporting and teaching people the strategies that can be applied to enhance their lives and relationships through improved self-awareness, human needs psychology, emotional intelligence and energy therapy. We all possess potential beyond our current level of performance, but at the same time an innate immunity to change. Coaching is recognised as the key enabler to change and personal development! Are you ready for your Next Chapter in your Epic Adventure called Life? If so come and visit SA:Si in The Secret Sanctuary or via video conference from anywhere in the world.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}