import React from 'react';
import { CallSplitOutlined, WbIncandescentOutlined, BusinessCenterOutlined, SupervisorAccountOutlined, SettingsInputAntennaOutlined } from '@material-ui/icons';
import styles from './whySasi.module.scss';

export default function WhySasi() {
  return (
    <>
      <div className={styles.whySasi}>
        <div className={`siteMaxWidth ${styles.siteMaxWidth}`}>
          <h2>
            WHY CHOOSE SA:SI ?
          </h2>
          <div className={styles.border}>
            <div className={styles.triangle}></div>
          </div>
          <div className={styles.choices}>
            <div className={styles.choice}>
              <div className={styles.titleBar}>
                <span className={styles.icon}><CallSplitOutlined /></span>
                <h4>REASON 1 - DIFFERENT APPROACH</h4>
              </div>
              <div>
              Sarah will only engage you if she believes she can help you.<br/><br/> Life changing differences usually occur within 6 weeks.<br/><br/> Customised programmes blended from a unique range of treatments and services.<br/><br/> No stop watch, sessions will end in line with your needs.<br/><br/> Majority of SA:SI clients are referrals.
              </div>
            </div>
            <div className={styles.choice}>
              <div className={styles.titleBar}>
                <span className={styles.icon}><WbIncandescentOutlined /></span>
                <h4>REASON 2 - STRATEGIC INTERVENTION</h4>
              </div>
              <div>
                You are desperately yearning for improvement and change<br/><br/> 
                You are feeling unfulfilled and unsatisfied with your life<br/><br/> 
                You don’t know how to be happy anymore, you have lost your direction and purpose<br/><br/> 
                You are at a crossroads and you are feeling overwhelmed by decisions you have to make
              </div>
            </div>
            <div className={styles.choice}>
              <div className={styles.titleBar}>
                <span className={styles.icon}><BusinessCenterOutlined /></span>
                <h4>REASON 3 - BUSINESS</h4>
              </div>
              <div>
              Your workforce is disengaged and demotivated<br/><br/> 
              People in your company are suffering from stress and burn out<br/><br/> 
              You want a successful profitable company<br/><br/> 
              You want a happy, motivated and high performing team
              </div>
            </div>
            <div className={styles.choice}>
              <div className={styles.titleBar}>
                <span className={styles.icon}><SupervisorAccountOutlined /></span>
                <h4>REASON 4 - RELATIONSHIP</h4>
              </div>
              <div>
                Sense of something missing/lost in the relationship<br/><br/> 
                Lack of communication with partner<br/><br/> 
                Escalating arguments/ circular arguments, distressing patterns of conflict<br/><br/> 
                Loss of intimacy and sexual desire
              </div>
            </div>
            <div className={styles.choice}>
              <div className={styles.titleBar}>
                <span className={styles.icon}><SettingsInputAntennaOutlined /></span>
                <h4>REASON 5 - ENERGY PSYCHOLOGY</h4>
              </div>
              <div>
              Past memories and emotions are stopping you from moving forward with your life<br/><br/> 
              You are suffering from low self esteem and confidence<br/><br/> 
              You continually feel stressed, anxious and overwhelmed<br/><br/> 
              Self-limiting beliefs are holding you back
              </div>
            </div>
          </div>
        </div>
      </div>
     </>
  );
}