import React from 'react';
import styles from './publicSpeaking.module.scss';

export default function PublicSpeaking() {
  return (
     <div>
       <div className="pageTitle">
        <div className="siteMaxWidth">
        <h1>Public Speaking</h1>
        </div>
   </div>
    <div className={`siteMaxWidth pageContainer`}>
    <div id="publicSpeaking">SA:SI offers public speaking on interesting and stimulating topics, based on Strategic Intervention and Modern  Psychology, and customises  them to the requirements of the organisation or event to fit in with the message, topic or theme they wish to communicate.  Sarah is an entertaining and thought provoking speaker, and her talks encapsulate her experiences, knowledge, conviction and personality, and she has a deep abiding desire to communicate and transfer her enthusiasm to her audiences!  Sarah has spoken to audiences of 10 -  2000 people, and has managed to keep them interested and enthralled during her performances.  Incorporating  her knowledge of  Strategic Intervention, Breakthrough Techniques and Strategies, Relationship Enhancement and Divorce prevention, Energy Therapy and Modern Psychology, Sarah has given educational and entertaining  talks at After Dinner speaking engagements, Training Seminars, Sales Motivation Presentations,  Conferences and Courses on the following topics amongst others -
<div className={styles.gridMeTop}>

<div class="blockTextInner"><h4>‘Managing  Change’</h4> What stops us from moving forward? Taking Action? Being Our Best?</div>

<div class="blockTextInner"><h4>‘How to ensure People Satisfaction’</h4> Customers, Suppliers, Employees, Employers!</div>

<div class="blockTextInner"><h4>‘Relationship Enhancement and Divorce Prevention’  </h4>How to Create Everlasting Love!</div>
</div>
<div className={styles.gridMeBottom}>
<div class="blockTextInner"><h4>‘Working towards Excellence’</h4> How do we ensure Continuous Improvement and Growth within our Organisations.</div>

<div class="blockTextInner"><h4>‘Human Needs Psychology’  </h4>The 6 Human Needs that every single person on the planet are driven to fulfil, no matter what!</div>
</div>
</div>
<p>Sarah was one of the Speakers at the European Foundation for Quality Management Conference where her presentation was translated  into 8 different languages, and published in Germany.</p>
<p>Sarah has also had  BBC radio and Television Appearances, both in the United Kingdom and in Europe, and has spoken at such recent events as Number One the Strand, Ladies Evening, Enterprise Assist Business Mentors Seminars,  The Little Britain Challenge Cup Charity Organisation Annual Sailing Regatta, and Kinnarps South West Launch Party.</p>
<p>If you would Like further information on engaging Sarah for an Event or Function, Please call or E-mail SA:SI now!</p>
    </div>
   </div>
  );
}