import React from 'react';
import {
    Link
  } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import logo from '../assets/logo_small.svg';
import Styles from './navBar.module.scss';

export default function Navbar(props) {

  const location = useLocation();

  return (
     <nav className={`sticky ${Styles.navBar} ${props.navShowing ?  Styles.showNav : ''}`}>
         <div className='siteMaxWidth'>
         <img className={Styles.logo} src={logo} alt="logo" />
            <ul>
                <li className={Styles.parent}>
                    <Link to="/" onClick={props.showNav} className={`${location.pathname === '/' ? Styles.active : ''}`}>What we offer</Link>
                        <ul className={Styles.subNav}>
                        {/* <li><Link to="/personalmindset" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/personalmindset' ? Styles.active : ''}`}>Personal Mindset</Link></li> */}
                        {/* <li><Link to="/ultimaterelationship" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/ultimaterelationship' ? Styles.active : ''}`}>Ultimate Relationship</Link></li> */}
                        <li><Link to="/strategicintervention" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/strategicintervention' ? Styles.active : ''}`}>Strategic Intervention</Link></li>
                        <li><Link to="/relationshipcounselling" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/relationshipcounselling' ? Styles.active : ''}`}>Relationship Counselling</Link></li>
                        <li><Link to="/eft" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/eft' ? Styles.active : ''}`}>E.F.T</Link></li>
                        <li><Link to="/matrix" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/matrix' ? Styles.active : ''}`}>Matrix Reimprinting</Link></li>
                        <li><Link to="/concentratedprograms" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/concentratedprograms' ? Styles.active : ''}`}>Concentrated Programs</Link></li>
                        <li><Link to="/businesscoaching" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/businesscoaching' ? Styles.active : ''}`}>Business Coaching</Link></li>
                        <li><Link to="/publicspeaking" onClick={props.showNav} className={`${Styles.child} ${location.pathname === '/publicspeaking' ? Styles.active : ''}`}>Public Speaking</Link></li>
                    </ul>
                    </li>
                    <li>
                    <Link to="/about" onClick={props.showNav} className={`${location.pathname === '/about' ? Styles.active : ''}`}>About Sarah</Link>
                    </li>
                    <li>
                    <Link to="/contactus" onClick={props.showNav} className={`${location.pathname === '/contactus' ? Styles.active : ''}`}>Contact Us</Link>
                    </li>
                
            </ul>
         </div>
    </nav>
  );
}