import React from 'react';
import img from '../../assets/images/Shaldon-Banner.jpg';
import styles from './concentratedPrograms.module.scss';

export default function ConcentratedPrograms() {
  return (
     <div>
           <div className="pageTitle">
       <div className="siteMaxWidth">
        <h1>Concentrated Programs</h1>
      </div>
     </div>
     <div className={`siteMaxWidth pageContainer`}>
       <div className={styles.imageHolder}>
          <img src={img} className={styles.img}></img>
       </div>
       <div class="bulletList concentratedBullet">
<h3>The programmes will be a blend of the following disciplines:</h3>
<ul>
<li>Strategic Intervention</li>
<li>Emotional Mastery</li>
<li>Energy Psychology</li>
<li>Emotional Freedom Technique</li>
<li>Matrix Re-imprinting</li>
<li>Reiki</li>
<li>Meditation</li>
<li>Vibrational reverberation</li>
<li>Ultimate Relationship knowledge (where appropriate)</li>
</ul>
</div>
<p><b>If you are feeling totally overwhelmed, feel you have no way out</b>, when you know you need to take massive action to sort out your life, your relationships, your career, and indeed your future, you need something that can help you identify and make the necessary changes as quickly as possible.</p>
<p>We offer concentrated 3 or 5 day programmes in the beautiful South Devon coastal setting of Shaldon and Teignmouth, for exactly this purpose, everyone bespoke and all on a one to one basis.</p>
<p>These are all delivered in a variety of environments both indoors and outdoors. There is a clear but flexible agenda presented at outset tailored to your needs from considered analysis prior to the programme.</p>
<p>Clients experience life changing differences each day on these programmes and leave fully equipped to embrace the life they want. Follow up refocus and refresh sessions are available as required.</p>
</div>
     </div>
  );
}