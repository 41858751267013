import React from 'react';
import img from '../../assets/images/SARAH ASHWORTH_041.jpg';
import styles from './contactUs.module.scss';

export default function ContactUs() {
  return (
    <div>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>CONTACT SA:SI</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer ${styles.pageContainer}`}>
      <div className={styles.contactText}>
      <h2>SA:SI Secret Sanctuary</h2>
        <p>
          7 Oystercatcher Court,<br />
          Bridge Road,<br />
          Shaldon,<br />
          Teignmouth,<br />
          TQ14 0BN
        </p>
        <p><b>Phone:</b> +44 (0)7896 220368</p>
        <p><b>Email: </b><a href="mailto:sarah@sasiuk.com">sarah@sasiuk.com</a></p>
        </div>
        <div className={styles.imageHolder}>
        <img src={img} alt="Secret Sanctuary"></img>
      </div>
      </div>
    </div>
  );
}