import React from 'react';
import img from '../../assets/images/matrixBanner.jpg'
import styles from './matrix.module.scss';

export default function Matrix() {
  return (
    <div>
    <div className="pageTitle">
       <div className="siteMaxWidth">
        <h1>Matrix Reimprinting</h1>
      </div>
     </div>
     <div className={`siteMaxWidth pageContainer ${styles.matrix}`}>
     <div className={styles.imageHolder}>
      <img className={styles.banner} src={img} alt="banner"></img>
      </div>
      
     <p>Matrix Re-imprinting is an advanced Meridian Tapping Therapy that everybody is talking about. It is effective for the treatment of emotional or physical issues; it can also be used to manifest your goals and dreams.</p>
<p>Like its predecessors, Matrix Re-imprinting also uses the Traditional Chinese Medicine meridian system that has been used in acupuncture for thousands of years. EFT or 'Tapping' has a long and impressive history of resolving physical and emotional health issues. The way it works is that you bring to mind and verbalise, in a specific manner, an issue that you want to work with.</p>
<div className={`bulletList ${styles.bullet}`}>Matrix Reimprinting has grown into a whole host of exciting protocols, including ones which can transform: 
<ul>
  <li>Addictions</li>
  <li>Phobias</li>
  <li>Allergies</li>
  <li>Negative Core beliefs</li>
  <li>Birth traumas</li>
  <li>Relationship issues</li>
</ul>
<p>…and many more. You can even work with past-lives and future selves, and also enhance your work with the Law of Attraction.</p>
</div>
<div className="blockQuote"><span className="quote"></span>At the same time you tap on points on your meridians with your fingers, and this releases stress and trauma from the body’s energy system, allowing the body-mind to return to a healthy physical and emotional state.</div>
<p>At the same time you tap on points on your meridians with your fingers, and this releases stress and trauma from the body’s energy system, allowing the body-mind to return to a healthy physical and emotional state. Results for EFT have always been phenomenal, and have far reaching positive consequences for our Western medical model of healing.</p>
<p>So how does Matrix Re-imprinting differ from this already established technique? Well, having evolved from EFT, it also contains some important differences. For example, in conventional EFT tapping on meridian end points is used to take the emotional intensity out of a past memory. What you are left with is to be able to recall your most traumatic and stressful life memories without any emotional disruption or stress, which is obviously extremely useful, as negative past memories keep the body in a state of stress and can contribute to disease. However, with Matrix Re-imprinting the memory is actually transformed. You can go into any past memory, say and do what you wished you’d said and done, bring in new resources, and create and transform the picture you have of that memory.</p>

<div className="blockQuote"><span className="quote"></span>In Matrix Re-imprinting we see these past negative memories as being held as pictures or holograms in your body-field.</div>
<p>In Matrix Re-imprinting we see these past negative memories as being held as pictures or holograms in your body-field. Until you transform them you keep tuning into them on a subconscious level and they affect your health, your wellbeing and your point of attraction. Changing the pictures creates both physical and emotional healing, and enables you to attract more positive experiences into your life. And by tapping on the meridians of the body at the same time, the process is accelerated.</p>
<p><b>It is important to recognize that this process is very different from denying what happened. From a quantum physics point of view we have any number of possible pasts or futures, and it is simply tuning us into one that is more resourceful, whilst releasing the stress and trauma that we hold in our body-mind and body-field, due to traumatic life events.</b></p>
     </div>
     </div>
  );
}