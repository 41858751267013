import React from 'react';
import { HomeOutlined, PhoneAndroidOutlined, EmailOutlined } from '@material-ui/icons';
import styles from './footer.module.scss';

export default function Footer() {
  return (
     <footer>
         <div className={`siteMaxWidth ${styles.footerContainer}`}>
            <div className={styles.footerItem}>
              <HomeOutlined/>
              <p>
              SA:SI<br/>
              7 Oystercatcher Court,<br />
          Bridge Road, Shaldon,<br />
          Teignmouth,<br />
          Devon, TQ14 0BN
              </p>
            </div>
            <div className={styles.footerItem}>
            <PhoneAndroidOutlined/>
              <div>
                <p>Phone: +44(0)7896 220 368</p>
              </div>
            </div>
            <div className={styles.footerItem}>
            <EmailOutlined/>
              <div>
                <a href="mailto:sarah@sasiuk.com">sarah@sasiuk.com</a>
              </div>
            </div>
            <div className={styles.footerItem}>
                <a href="/privacypolicy">Privacy Policy</a>
            </div>
         </div>
    </footer>
  );
}