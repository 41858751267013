import React from 'react';
import eft from '../../assets/images/EFT_Banner.png';
import logo from '../../assets/images/EFT_Logo.png';
import quotes from '../../assets/images/speachMarks.png';
import Styles from './banner.module.scss';

export default function SasiBanner() {
  return (
    <>
    <div className={Styles.bannerBackground}></div>
     <div className="siteMaxWidth">
       <div className={Styles.banner}>
         <div className={Styles.imageContainer}>
            <img src={eft} alt="E.F.T"/>
         </div>
         <div className={Styles.logoContainer}>
            <img src={logo} alt="Ancient Acupressure, Modern Psychology"/>
         </div>
       </div>
     </div>
     </>
  );
}