import React from 'react';
import styles from './audio.module.scss';

export default function Audio() {
  return (

    <div className={styles.container}>
        <div className={styles.titleBar}>
            <h3>SARAH'S LATEST PODCAST</h3>
        </div>
        <iframe src="https://player.acast.com/5ed8d6f6dd6c7578b007f928/episodes/all-things-family-law-episode-five" frameBorder="0" width="100%" height="110px" allow="autoplay"></iframe>
    </div>
    
  );
}