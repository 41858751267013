import React from 'react';
import sarah from '../../assets/images/sarahStand.png';
import quotes from '../../assets/images/speachMarks.png';
import Styles from './banner.module.scss';

export default function SasiBanner() {
  return (
    <>
    <div className={Styles.bannerBackground}></div>
     <div className="siteMaxWidth">
       <div className={Styles.banner}>
         <div className={Styles.sarahContainer}>
            <img src={sarah} alt="Sarah Ashworth"/>
         </div>
         <div className={Styles.quoteContainer}>
         <img className={Styles.speachMarks} src={quotes} alt="Speach Marks"/>
            <div className={Styles.quote}>
              We all possess <span className={Styles.blueText}>potential beyond our current level</span> of performance but at the same time, an <span className={Styles.blueText}>innate immunity to change</span>.
            </div>
         </div>
       </div>
     </div>
     </>
  );
}