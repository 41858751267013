import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LocationOnOutlined,BusinessCenterOutlined, EmojiSymbolsOutlined, SettingsInputAntennaOutlined, MicNoneOutlined, WbIncandescentOutlined, SupervisorAccountOutlined } from '@material-ui/icons';
import styles from './accordion.module.scss';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

export default function CustomizedExpansionPanels() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
          <div className={styles.accordionTitle}><WbIncandescentOutlined/>STRATEGIC INTERVENTION</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          After studying over many years and gaining many qualifications, 
          I have found Strategic Intervention coaching, developed by Anthony Robbins and 
          Cloe Madanes using a combination of recognized forms of psychological therapies, 
          is the most powerful, logical and effective way to make rapid and positive life 
          lasting changes.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
          <div className={styles.accordionTitle}><SupervisorAccountOutlined />RELATIONSHIP COUNSELLING</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Relationships are an essential part of our lives, from family and friends to partners 
          and spouses. Indeed, one of the 6 human needs that everyone shares is the need for Love 
          and Connection which drives us to develop intimate and intense relationships with a few 
          select individuals, which can bring tremendous joy and happiness. Difficulties in 
          relationships are one of the most common causes of unhappiness, and distress.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
          <div className={styles.accordionTitle}><SettingsInputAntennaOutlined />E.F.T</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          EFT, or ‘Tapping’, is a powerful tool for improving your life on many different 
          levels: mental, emotional and physical. It can be used to remove fears, doubts and upsets, 
          and has been proven to effectively address a range of issues from anxiety, chronic pain, 
          addiction, fear, weight control, stress relief, phobias, self-limiting beliefs, financial 
          abundance, and so much more.

          *E.F.T has scientifically proven to reduce Cortisol levels and other stress hormones by 24% 
          within a one hour session
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
         <ExpansionPanelSummary
         expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.accordionTitle}><EmojiSymbolsOutlined />MATRIX RE-IMPRINTING</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Quite often, having a coach to help define your goals, understand the reality of where you 
          are now, what might stop you reaching them, work with you to develop a plan of how you are 
          going to achieve them, and then hold you accountable until you reach your desired outcome, 
          will be the decisive factor for your success.
          <br/><br/>
          Why then, knowing what we have to do, sometimes we don‘t do what we know we should? Deep seated 
          thoughts, emotions and fears stop us from reaching our full potential. Matrix Re-imprinting can 
          help you re-frame or discard them.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
         <ExpansionPanelSummary
         expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <div className={styles.accordionTitle}><LocationOnOutlined />3-5 DAY INTENSE COURSES</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          If you are feeling totally overwhelmed, feel you have no way out, when you know you need to take 
          massive action to sort out your life, your relationships, your career, and indeed your future, 
          you need something that can help you identify and make the necessary changes as quickly as possible.
          <br/><br/>
          We offer concentrated 3 or 5 day programmes in the beautiful South Devon coastal setting of Shaldon 
          and Teignmouth, for exactly this purpose, everyone bespoke and all on a one to one basis.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
         <ExpansionPanelSummary
         expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <div className={styles.accordionTitle}><BusinessCenterOutlined />BUSINESS COACHING</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          At SA:SI, we believe that you can have the best systems in place, the most up to date technology, 
          the smartest offices, the most incredible vision, but…….without investing thought, time and money 
          into the people you expect to help you create your vision, you will be unable to inspire, motivate 
          and equip them with the best attitude, aptitude and desire required to work along side you in 
          creating a profitable, happy, enthusiastic company.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
         <ExpansionPanelSummary
         expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <div className={styles.accordionTitle}><MicNoneOutlined />PUBLIC SPEAKING</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          SA:SI offer interesting and stimulating topics, based on Strategic Intervention and Modern Psychology, and customises them to the requirements of the organisation or event to fit in with the message, topic or theme they wish to communicate.<br /><br />Sarah is an entertaining and thought provoking speaker, and her talks encapsulate her experiences, knowledge, conviction and personality, and she has a deep abiding desire to communicate and transfer her enthusiasm to her audiences!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}