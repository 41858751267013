import React from 'react';
import SasiBanner from './banner';
import Steps from './steps';
import Accordion from './accordion';
import Tabs from './tabs';
import WhySasi from './whySasi';
import Testimonials from './testimonials';
import Audio from './audio';
import { SettingsVoiceOutlined } from '@material-ui/icons';
import styles from './sasi.module.scss';


export default function Sasi() {
  return (
    <>
      <SasiBanner />
      <Steps />
      <div className={`siteMaxWidth ${styles.row1}`}>
        <div>
          <div className={styles.accordion}>
            <Accordion/>
          </div>
          <div className={styles.tabs}>
            <Tabs />
          </div>
        </div>
        <div className={styles.fromSarah}>
          <h4>From Sarah</h4>
          <SettingsVoiceOutlined className={styles.bulb}></SettingsVoiceOutlined>
            Thank You for visiting the Sarah Ashworth : Strategic Intervention (SA:SI) website! Perhaps you 
            are at a crossroads in your life, and are feeling overwhelmed and stressed because your life seems 
            out of your own control, that other people’s actions and behaviour are impacting on your happiness 
            and wellbeing. It could be your relationship is falling apart and you don’t know why, you love each 
            her, but can’t seem to live together without arguments and disparaging remarks. Maybe you are sick 
            and fed up of continually feeling stressed, anxious, depressed and overwhelmed. I hope you find 
            what you are looking for here, it would be an honour to help you find your direction again. 
            If you require any further information, or would like to book a free no obligation consultation to 
            see how Sarah Ashworth : Strategic Intervention can help transform your life, please drop me an
             e-mail or call in total confidence,
            <br/><br/>
            Through understanding your own needs, values and beliefs and how you meet them, Strategic Intervention 
            coaching will give you the clarity and insight into the decisions you have made, why you have made
             them, and the ability to make empowering decisions in the future.
        </div>
      </div>
      <WhySasi/>
      <div className={styles.testimonialsAndRadio}>
        <div className={`siteMaxWidth ${styles.parts}`}>
          <Testimonials/>
          <Audio />
        </div>
      </div>
      
     </>
  );
}