import React from 'react';
import img from '../../assets/images/businessCoaching.png';
import companies from '../../assets/images/compLogos.png';
import styles from './businessCoaching.module.scss';

export default function BusinessCoaching() {
  return (
    <div>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>Business Coaching</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer`}>
      <div className={styles.imageHolder}>
        <img className={styles.banner} src={img} alt="Banner"></img>
      </div>
        
        <p><strong>I am currently a registered and approved Growth Coach and Leadership and Management Trainer for GrowthAccelerator. GrowthAccelerator is where ambitious businesses go for new connections, new routes to investment and the new ideas and strategy they need to achieve their full potential.</strong>.</p>
        <p>Everybody starts a career wanting to be the best he/she can, and do the best for the company that has given them this amazing opportunity of showing what they can do. It is the responsibility of the leader to create a working environment where people feel empowered, trusted and safe, enabling them to do the best job possible.</p>
        <p><span>A great leader can make a business thrive.  But having a whole team of great leaders could propel your business into rapid growth!</span></p>
        <p>My role as a Growth Coach is to work with companies on a one-to-one basis providing relevant and individual support. As a Leadership and Management Trainer we provide workshops and seminars which cover key areas including:<br />Developing an effective personal leadership and management style, Leading and managing high performance, Business Development and Sales Training, Planning and developing an effective organisation, creating a joint enterprise culture, Sustaining growth and continuous improvement, Embedding a culture of innovation.</p>
        <div className={styles.gridMe}>
          <div className="blockTextInner">
            <h4>EMPOWER</h4>
  them by giving them the training, skills and equipment they need to do their job well.
</div>
          <div className="blockTextInner">
            <h4>TRUST</h4>
  them to do the best job possible.
</div>
          <div className="blockTextInner">
            <h4>MOTIVATE</h4>
  them by understanding and meeting their unique needs and drivers, not everyone works just for the pay cheque.
</div>
          <div className="blockTextInner">
            <h4>RESPECT</h4>
  them for their opinions and contributions, quite often the people doing the work are best placed to understand exactly what is needed.
</div>
          <div className="blockTextInner">
            <h4>INSPIRE</h4>
  them to be the best they can be.
</div>
          <div className="blockTextInner">
            <h4>GRATITUDE</h4>
  – Thank them!  Acknowledge their efforts and the extra miles they go for the company.
</div>
        </div>
        <p>High productivity is achieved with far less costly machinery, business systems, and cutting edge technology  in companies that appreciate, listen to and acknowledge the people who work there.  Let SA:SI show you how to have an Inspired, Motivated, Happy, Successful Workforce through our Inspirational Leadership and Management Courses, Individual and Team Coaching, Consulting/Mentoring Programmes, and our Motivational Sales Training Workshops.</p>
        <h3>Clients, past and present:</h3>
        <img className={styles.companies} src={companies} alt="Company Logos" />
      </div>
    </div>
  );
}