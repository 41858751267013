import React from 'react';
import logo from '../assets/logo.svg';
import { MenuOutlined } from '@material-ui/icons';
import {
  Link
} from "react-router-dom";
import Styles from './header.module.scss';

function Header(props) {

  return (
      <header>
          <div className="siteMaxWidth">
            <div className={Styles.logoContainer}>
                <Link to="/"><img className={Styles.logo} src={logo} alt="logo" /></Link>
            </div>
            <button className={Styles.burger} onClick={props.showNav}>
              <MenuOutlined/>
            </button>
            <ul className={Styles.socialMedia}>
              {/* <li>
              <span className={Styles.socialBackground}></span>
                <a className={Styles.facebook} href="https://www.facebook.com/sarahashworthstrategicintervention/"></a>
              </li> */}
              <li>
                <span className={Styles.socialBackground}></span>
                <a className={Styles.twitter} rel="noopener noreferrer" href="https://twitter.com/Sarah_SASIUK" target="_blank"></a>
              </li>
            </ul>
          </div>
      </header>
  );
}

export default Header;
