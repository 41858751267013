import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { LocationOnOutlined,BusinessCenterOutlined, EmojiSymbolsOutlined, SettingsInputAntennaOutlined, MicNoneOutlined, WbIncandescentOutlined, SupervisorAccountOutlined } from '@material-ui/icons';
import styles from './tabs.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Services Descriptions"
        className={styles.tabs}
      >
        <Tab icon={<WbIncandescentOutlined />} label="STRATEGIC INTERVENTION" {...a11yProps(0)} />
        <Tab icon={<SupervisorAccountOutlined />} label="RELATIONSHIP COUNSELLING" {...a11yProps(1)} />
        <Tab icon={<SettingsInputAntennaOutlined />} label="E.F.T" {...a11yProps(2)} />
        <Tab icon={<EmojiSymbolsOutlined />} label="MATRIX RE-IMPRINTING" {...a11yProps(3)} />
        <Tab icon={<LocationOnOutlined />} label="3-5 DAY INTENSE COURSES" {...a11yProps(4)} />
        <Tab icon={<BusinessCenterOutlined />} label="BUSINESS COACHING" {...a11yProps(5)} />
        <Tab icon={<MicNoneOutlined />} label="PUBLIC SPEAKING" {...a11yProps(6)} />
      </Tabs>
      <div className={styles.panels}>
      <TabPanel className={styles.tabPanel} value={value} index={0}>
      After studying over many years and gaining many qualifications, I have found Strategic Intervention coaching, developed by Anthony Robbins and Cloe Madanes using a combination of recognized forms of psychological therapies, is the most powerful, logical and effective way to make rapid and positive life lasting changes.
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={1}>
      Relationships are an essential part of our lives, from family and friends to partners and spouses. Indeed, one of the 6 human needs that everyone shares is the need for Love and Connection which drives us to develop intimate and intense relationships with a few select individuals, which can bring tremendous joy and happiness. Difficulties in relationships are one of the most common causes of unhappiness, and distress.
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={2}>
      EFT, or ‘Tapping’, is a powerful tool for improving your life on many different levels: mental, emotional and physical. It can be used to remove fears, doubts and upsets, and has been proven to effectively address a range of issues from anxiety, chronic pain, addiction, fear, weight control, stress relief, phobias, self-limiting beliefs, financial abundance, and so much more. <br /><br /> *E.F.T has scientifically proven to reduce Cortisol levels and other stress hormones by 24% within a one hour session
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={3}>
      Quite often, having a coach to help define your goals, understand the reality of where you are now, what might stop you reaching them, work with you to develop a plan of how you are going to achieve them, and then hold you accountable until you reach your desired outcome, will be the decisive factor for your success.<br /><br />Why then, knowing what we have to do, sometimes we don‘t do what we know we should? Deep seated thoughts, emotions and fears stop us from reaching our full potential. Matrix Re-imprinting can help you re-frame or discard them.
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={4}>
      If you are feeling totally overwhelmed, feel you have no way out, when you know you need to take massive action to sort out your life, your relationships, your career, and indeed your future, you need something that can help you identify and make the necessary changes as quickly as possible.<br /><br />We offer concentrated 3 or 5 day programmes in the beautiful South Devon coastal setting of Shaldon and Teignmouth, for exactly this purpose, everyone bespoke and all on a one to one basis.
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={5}>
      At SA:SI, we believe that you can have the best systems in place, the most up to date technology, the smartest offices, the most incredible vision, but…….without investing thought, time and money into the people you expect to help you create your vision, you will be unable to inspire, motivate and equip them with the best attitude, aptitude and desire required to work along side you in creating a profitable, happy, enthusiastic company.
      </TabPanel>
      <TabPanel className={styles.tabPanel} value={value} index={6}>
      SA:SI offer interesting and stimulating topics, based on Strategic Intervention and Modern Psychology, and customises them to the requirements of the organisation or event to fit in with the message, topic or theme they wish to communicate.<br /><br />Sarah is an entertaining and thought provoking speaker, and her talks encapsulate her experiences, knowledge, conviction and personality, and she has a deep abiding desire to communicate and transfer her enthusiasm to her audiences!
      </TabPanel>
      </div>
    </div>
  );
}