import React from 'react';
import styles from "./personalMindset.module.scss"
import banner from "../../assets/images/personalMindset.png";

export default function PersonalMindset() {
  return (
     <div>
       <div className="pageTitle">
         <div className="siteMaxWidth">
          <h1>Personal Mindset</h1>
        </div>
       </div>
       <div className={`siteMaxWidth pageContainer`}>
          <img src={banner}></img>
          <h2>Realise Your True Potential</h2>
          <p><strong>The Personal Mindset Mastery is an exclusive FULL DAY Mastermind event that will give you absolute clarity on where you want to be in all aspects of your life.</strong></p>
          <p>Are you being swept away by things that are out of your control, buried under daily responsibilities, financial pressures, work commitments, digital distractions and the exhausting business of modern day life? This event will give you the cutting-edge knowledge, tools and strategies to become the best version of yourself and live the quality of life you desire and deserve. You will understand:</p>
          <div className="bulletList">
            <h4>Join me at this exclusive event to transform your life!</h4>
            <ul>
              <li>What influences every choice that you make.</li>
              <li>The self-limiting beliefs that are holding you back.</li>
              <li>How to challenge and release your brakes.</li>
              <li>The power of your thoughts and emotional mastery.</li>
              <li>How to realise your true potential and become the person you were born to be.</li>
            </ul>
          </div>
          <p><strong>Tickets £175.00. Early Bird rate £150.00 Early Bird offer if booked before 30th August 2019</strong></p>
          <p><strong>The event day starts at 9.00am until 5.30pm and includes parking, refreshments throughout the day and a buffet lunch.</strong></p>
          <p>Please contact The Osbourne Hotel, Torquay and quote this event to claim an exclusive discount off B&amp;B and Dinner B&amp;B rates.</p>
          <p><strong>This event is part of a weekend life mastery academy consisting of both Personal Mindset Mastery on Saturday 28th &amp; Ultimate Relationship Boost on Sunday 29th September. If you book to attend both days – use the exclusive discount code “SASIMastery” to attend BOTH events for £250.</strong></p>
       </div>
     </div>
  );
}