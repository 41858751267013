import React from 'react';
// import Styles from './privacyPolicy.module.scss';

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>CONTACT SA:SI</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer`}>

      <p><strong>Website Privacy Policy</strong></p>



      <p>This privacy notice provides you with details of
how we collect and process your personal data through your use of our site <a href="http://www.sasiuk.com/">www.sasiuk.com</a>. &nbsp;By providing us with your data,
you warrant to us that you are over 13 years of age.&nbsp;&nbsp;SASI is the
data controller and we are responsible for your personal data (referred to as
“we”, “us” or “our” in this privacy notice).</p>



      <p><strong>Contact Details</strong></p>



      <p>Full name of legal entity: <strong>SA:Si</strong></p>



      <p>Email address: <strong>sarah@sasiuk.com</strong> </p>



      <p>Postal address: <strong>SA:SI, Office 8, Pavillions, Teignmouth, Devon, TQ14 8BG</strong> </p>



      <p>Telephone number: <strong>+44(0)1626 249 048</strong> </p>



      <p>Company registered in the UK, number: <strong></strong></p>



      <p>It is very important that the information we hold
      about you is accurate and up to date. Please let us know if at any time your
personal information changes by emailing us at: <strong>sarah@sasiuk.com</strong></p>



      <p><strong>Types of Personal
Data</strong></p>



      <p><strong>What data do we collect about you, for what purpose
and how we process it.&nbsp;&nbsp;</strong>Personal data means
any information capable of identifying an individual, it does not include
anonymized data.&nbsp; The majority of the personal data that we process is
provided to us directly by you for a range of reasons such as you have made an
enquiry, you have made an information request, you want to or have attended an
event, and/or you subscribe to the Smarter Accounting newsletter. &nbsp;For
example, you have provided us with your business card and are connected with us
on social media channels such as LinkedIn whereby we may contact you to ask for
your consent to future marketing such as to join our email newsletter
list.&nbsp;&nbsp;We process your personal data when it is necessary to comply
with a legal obligation, to carry out a contract, for legitimate interests and
where consent is given.&nbsp; We may collect and process the following
categories of personal data about you:</p>



      <p>Communication Data&nbsp;that includes any communication
      that you send to us whether that be through the contact form on our website,
      through email, text, social media messaging, social media posting or any other
      communication that you send us such as via our third-party parties, including
      the likes of Google, Hootsuite, Eventbrite and Mailchimp. This data collected
      may include name, email address, address, phone number, date of birth, business
      name and address, financial details, personal information you provide to us
      such as feedback forms, Google forms, and survey information. &nbsp;We process
      this data for the purposes of communicating with you, for record keeping and
      for relationship management. &nbsp;Our lawful grounds for this processing is
      our legitimate interest which in this case are to reply to communications sent
to us and to keep records.</p>



      <p>Client Data&nbsp;that includes data relating to any
      purchases of services such as your name, title, invoice address, delivery
      address, email address, phone number, contact details, purchase details.
      &nbsp;We process this data to supply the services you have purchased and to
      keep records of such transactions. &nbsp;Our lawful grounds for this processing
      is the performance of a contract between you and us and/or taking steps at your
      request to enter into such a contract, informing you about updates or services
and record keeping.</p>



      <p>Prospect Data&nbsp;that includes data you have
      supplied in requesting information about our services and/or to access
      resources such as free downloads and guides. We will process this data to reply
      to you and for recordkeeping purposes, for example. Our lawful grounds for
processing is legitimate interest.</p>



      <p>User Data&nbsp;that includes data about how you use
      our website and any online services together with any data that you post for
      publication on our website or through other online services. We process this
      data to operate our website and ensure relevant content is provided to you, to
      ensure the security of our website, to maintain back-ups of our website and/or
      databases and to enable publication and administration of our website, other
      online services and business. &nbsp;Our lawful grounds for this processing is
      our legitimate interest which in this case enables us to properly administer
our website and our business.</p>



      <p>Technical Data&nbsp;that includes data about your
      use of our website and online services such as your IP address, your login
      data, details about your browser, length of visit to pages on our website, page
      views and navigation paths, details about the number of times you use our
      website, time zone settings and other technology on the devices you use to
      access our website. The source of this data is from our analytics tracking
      system. We process this data to analyse your use of our website and other
      online services, to administer and protect our business and website, to deliver
      relevant website content and advertisements to you and to understand the
      effectiveness of our advertising. Our lawful grounds for this processing is our
      legitimate interest which in this case are to enable us to administer our
website and to determine our marketing strategy.</p>



      <p>Mailing List Data&nbsp;to send email newsletters
      and details of our services and offers. We use Hootsuite and MailChimp to
deliver our emails.</p>



      <p><a href="https://hootsuite.com/en-gb/legal/privacy">Hootsuite’s privacy
policy</a></p>



      <p><a href="https://mailchimp.com/legal/privacy">MailChimp’s privacy
policy&nbsp;</a></p>



      <p>We hate spam and only send our email newsletters to
      those people that have opted-in to receive them. You can unsubscribe at any
      time by clicking the unsubscribe link at the bottom of our emails or by
emailing&nbsp;&lt;EMAIL&gt;</p>



      <p>Marketing Data includes data about your preferences
      in receiving marketing from us and our third parties and your communication
      preferences. We process this data to enable you to partake in our promotions
      such as competitions, prize draws and free give-aways, to deliver relevant
      website content and advertisements to you and measure or understand the
      effectiveness of this advertising. Our lawful grounds for this processing is
      our legitimate interest which in this case are to study how clients use our
services, to develop our services and determine our marketing strategy.</p>



      <p>We may use&nbsp;Customer Data, User Data, Technical
      Data and Marketing Data&nbsp;to deliver relevant website content and
      advertisements to you (including Facebook adverts or other display
      advertisements) and to measure or understand the effectiveness of our
      advertising. Our lawful grounds for this processing this data is legitimate
      interest. We may also use such data to send other marketing communications to
      you. Our lawful grounds for this processing this data is either consent or
      legitimate interest.&nbsp; We do not carry out automated decision making or any
type of automated profiling.</p>



      <p><strong>Sensitive Data</strong></p>



      <p>We will not collect any Sensitive Data about you.</p>



      <p><strong>Personal Data
Collection</strong></p>



      <p>We may collect data about you by you providing the
      data directly to us (for example by filling in forms on our site, by sending us
      emails or providing us with your contact details such as business cards).
      &nbsp;We may automatically collect certain data from you as you use our website
      by using cookies and similar technologies. We may receive data from third
      parties such as analytics providers such as Google based outside the EU,
      advertising networks such as Facebook based outside the EU, such as search
      information providers such as Google based outside the EU, providers of
      technical, payment and delivery services, such as data brokers or
      aggregators.&nbsp; We may also receive data from publicly available sources
      such as Companies House and the Electoral Register based inside the EU. We may
also collect data from social media channels such as Twitter.</p>



      <p><strong>Marketing
Communications</strong></p>



      <p>Our lawful grounds of processing your personal data
      in order to send marketing communications is either with your consent or in our
      legitimate interests.&nbsp; Under the Privacy and Electronic Communications
      Regulations, we may send you marketing communications from us if (i) you made a
      purchase or asked for information from us about our services or (ii) you agreed
      to receive marketing communications and in each case you have not opted out of
      receiving such communications since, including to receive a freebie or
      download, for example, where there will be follow-up emails. Under these
      regulations, if you are a limited company, we may send you marketing emails
      without your consent and you can opt out of receiving marketing emails from us
      at any time.&nbsp;&nbsp;Before we share your personal data with any third party
      for their own marketing purposes we will get your express consent.&nbsp; You can
      ask us or third parties to stop sending you marketing messages at any time by
      following the opt-out links on any marketing message sent to you or by emailing
us at&nbsp;&lt;EMAIL&gt;</p>



      <p><strong>Data Sharing</strong></p>



      <p>See the section on how we collect your personal
      data for more details on data sharing. We may have to share your personal data
      with:&nbsp;Service providers who provide IT and system administration services;
      Professional advisers including solicitors, bankers, auditors and insurers;
      Government bodies that require us to report processing activities; Third
      parties to whom we sell, transfer, or merge parts of our business or our
      assets; Suppliers, clients, in respect of marketing activity.&nbsp;&nbsp;We
      require all third parties to whom we transfer your data to respect the security
      of your personal data and to treat it in accordance with the law. We only allow
      such third parties to process your personal data for specified purposes and in
accordance with our instructions.</p>



      <p><strong>International
Transfers</strong></p>



      <p>We are subject to the provisions of the General Data
      Protection Regulations (GDPR) that protect your personal data. Where we
      transfer your data to third parties outside of the EEA, we will ensure that
      certain safeguards are in place to ensure a similar degree of security for your
      personal data. &nbsp;We may transfer your personal data to countries that the
      European Commission have approved as providing an adequate level of protection
      for personal data by; or if we use US-based providers that are part of EU-US
      Privacy Shield, we may transfer data to them, as they have equivalent
      safeguards in place; or where we use certain service providers who are
      established outside of the EEA, we may use specific contracts or codes of
      conduct or certification mechanisms approved by the European Commission which
      give personal data the same protection it has in Europe.&nbsp;&nbsp;If none of
      these safeguards is available, we may request your explicit consent to the
      specific transfer.&nbsp; You will have the right to withdraw this consent at
any time&nbsp;by emailing us at&nbsp;&lt;EMAIL&gt;.</p>



      <p><strong>Data Security</strong></p>



      <p>We have put in place security measures to prevent
      your personal data from being accidentally lost, used, altered, disclosed, or
      accessed without authorisation. &nbsp;We also allow access to your personal
      data only to those employees and partners who have a legitimate business need
      to know such data. &nbsp;They will only process your personal data on our
      instructions and they must keep it confidential.&nbsp; We have procedures in
      place to deal with any suspected personal data breach and will notify you and
any applicable regulator of a breach if we are legally required to.</p>



      <p><strong>Data Retention</strong></p>



      <p>We will only retain your personal data for as long
      as necessary to fulfil the purposes we collected it for, including for the
      purposes of satisfying any legal, accounting, or reporting requirements.
      &nbsp;When deciding what the correct time is to keep the data for we look at
      its amount, nature and sensitivity, potential risk of harm from unauthorised
      use or disclosure, the processing purposes, if these can be achieved by other
      means and legal requirements.&nbsp; For tax purposes, the law requires us to
      keep basic information about our customers (including Contact, Identity,
      Financial and Transaction Data) for six years after they stop being
      customers.&nbsp; Please contact us to learn more about our Data Retention
Policy.</p>



      <p><strong>Your Legal Rights</strong></p>



      <p>Under data protection laws you have rights in
      relation to your personal data that include the right to request access,
      correction, erasure, restriction, transfer, to object to processing, to
      portability of data and (where the lawful grounds of processing is consent) to
withdraw consent.&nbsp; <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">Click here
for more information</a>.&nbsp; If you wish
to exercise any of the rights set out above, please email us at&nbsp;<a href="mailto:dean@growdie.com"> &lt;EMAIL&gt;.</a>&nbsp; You will not have to pay a fee to access your personal data (or
to exercise any of the other rights). However, we may charge a reasonable fee
if your request is clearly unfounded, repetitive or excessive or refuse to
comply with your request in these circumstances.&nbsp;&nbsp;We may need to
request specific information from you to help us confirm your identity and
ensure your right to access your personal data (or to exercise any of your
other rights). This is a security measure to ensure that personal data is not
disclosed to any person who has no right to receive it. We may also contact you
to ask you for further information in relation to your request to speed up our
response.&nbsp; We aim to respond to all legitimate requests within one month.
&nbsp;Occasionally it may take us longer than a month if your request is
particularly complex or you have made a number of requests, in this case, we
will notify you.</p>



      <p>If you are not happy with any aspect of how we
      collect and use your data, you have the right to complain to the Information
      Commissioner’s Office (ICO), the UK supervisory authority for data protection
issues (<a href="http://www.ico.org.uk/">www.ico.org.uk</a>). We should be grateful if you would contact us first if you do have a
complaint so that we can try to resolve it for you.</p>



      <p><strong>Third-Party Links</strong></p>



      <p>This website may include links to third-party
      websites, plug-ins and applications. &nbsp;Clicking on those links or enabling
      those connections may allow third parties to collect or share data about you.
      We do not control these third-party websites and are not responsible for their
      privacy statements.&nbsp; When you leave our website, we encourage you to read
the privacy notice of other websites that you visit.</p>



      <p><strong>Cookies</strong></p>



      <p>You can set your browser to refuse all or some
      browser cookies, or to alert you when websites set or access cookies. If you
      disable or refuse cookies, please note that some parts of this website may
become inaccessible or not function properly.</p>



      <p><strong>Changes</strong></p>



      <p>We reserve the right to make changes to this
      Privacy Notice. It was last reviewed and updated in line with GDPR on 13/08/19,
and will be reviewed on an ongoing basis.</p>



      <p><strong>Further Information</strong></p>



      <p><a href="https://ico.org.uk/">The
Information Commissioner</a></p>
    </div>
    </div>
  );
}