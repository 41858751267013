import React, {useState, useEffect} from 'react';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import manSilhouette from "./../../assets/images/manSilhouette.png";
// import womanSilhouette from "";
import styles from './testimonials.module.scss';

const testimonials = [
  {
    name:'STEVE G',
    description:'BUSINESS CONSULTANT AND MENTOR',
    quote:'I thought your presentation at the BIP Government Enterprise Assist Mentors meeting on Managing Change was excellent and I can only hope that half my presentations go as well.',
    icon:'man'
  },
  {
    name:'DIANA MUREK',
    description:'DIRECTOR OF EDUCATION',
    quote:'Last year you  delivered a fantastic seminar to our alumni cohort that we were training to become tutors. It was really great and inspiring and we wish to invite you back to do the same seminar this year',
    icon:'man'
  },
  {
    name:'MRS R',
    description:'',
    quote:'I met with Sarah who bore the brunt of my anger during the session. She listened and didn’t judge. She simply talked to me. I didn’t value what she was saying at the time, I suppose I was still very angry, but after a period of time reflecting on what she’d said I gained clarity and started to see things differently.',
    icon:'man'
  },
  {
    name:'L FROM SOMERSET',
    description:'',
    quote:'Anyway, Thank you for all your wisdom and advice and for listening without judging. I’m glad we both had the opportunity to talk with you.',
    icon:'man'
  },
  {
    name:'S & J FROM LONDON',
    description:'',
    quote:' What an amazing week!  Started off with divorce lawyers, anger and recrimination then 5 days with you and finished on understanding, forgiveness, and love fun and intimacy!!!  Thank You, Thank You, Thank You!',
    icon:'man'
  },
  
]

export default function Testimonials() {
  const [leftPosition, setLeftPosition] = useState(0);
  const testimonialsTotal = testimonials.length - 1;
  const mql = window.matchMedia('(max-width: 720px)');
  const slideWidth = (mql.matches) ? window.innerWidth : 600;


  useEffect(() => {
    const timer = () => {setTimeout(() => {
      //scrollRight();
      timer();
    }, 1000);
  }
  timer();
}, []);

  const scrollRight = () => {
    if(leftPosition > -(slideWidth * testimonialsTotal)){
      setLeftPosition(leftPosition - slideWidth);
    }else{
      setLeftPosition(0);
    }
  }

  const scrollLeft = () => {
    if(leftPosition < 0){
      setLeftPosition(leftPosition + slideWidth);
    }else{
      setLeftPosition(-(slideWidth * testimonialsTotal));
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <h3>WHAT OUR CLIENTS SAY</h3>
        <div className={styles.arrows}>
          <button onClick={scrollLeft}><ArrowBackIosOutlined/></button>
          <button onClick={scrollRight}><ArrowForwardIosOutlined/></button>
          
        </div>
      </div>
    <div className={styles.testimonials} style={{ transform : `translate3d(${leftPosition}px, 0px, 0px)` }}>
      {
      testimonials.map((person, index) => (
        
      <div key={`testimoinal_${index}`} className={styles.testimonial}>
      <div className={styles.person}>
        <div className={styles.picture}>
          <img src={manSilhouette} alt="personSilhouette" />
        </div>
        <h5>{person.name}</h5>
        <span className="smallText">{person.description}</span>
      </div>
      <div className={styles.textContainer}>
      <div className={styles.textBlock}>
        {person.quote}
      </div>
      <div className={styles.textBlockBackground}>
      </div>
      </div>
    </div>
    
    ))}
</div>
    </div>
  );
}