import React from 'react';
import styles from './strategicIntervention.module.scss';

export default function StrategicIntervention() {
  return (
    <div id="strategicIntervention">
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>Strategic Intervention</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer`}>
        <div className="blockQuote"><span className="quote"></span>The Quality of Your life is found in meaning.  Life is not the challenges you face, but the meaning you give those challenges.</div>
        <p>Strategic Intervention coaching works on the scientifically proven research over decades that<b> all human behaviour </b>is driven by the pursuit to fulfil one or more of our Six Universal Human Needs. Once you know and understand these universal needs in yourself and others, you can develop amazing relationships with your partners, children, other family members, work colleagues and friends.  If you can meet a person’s human needs at a high level, your relationship will withstand any trial or tribulation. Strategic Intervention personal coaching helps you understand your own human needs, what it takes for you to meet them, and how your needs may conflict with the human needs of other people in your life, creating dis-harmony, personality clashes, relationship conflicts, and much more. <strong>When someone is in crisis, or they are unhappy and dissatisfied with their life, or they don’t feel fulfilled, usually it is due to the fact that a person’s life conditions have changed and they have lost their usual means for meeting their needs.</strong> Why? When an event occurs in our lives, we begin processing the situation through thought; we then give this thought meaning.   From this meaning, we create emotions. The quality of your life depends on the quality of your emotions. Strategic Intervention life coaching helps people create empowering and fulfilling meanings to events which happen in their lives, which in turn, elevates the quality of your emotions, and therefore improves the quality of your life dramatically!</p>
        <p>As a Strategic Intervention Life coach, I not only help solve the immediate situation, but also grow and expand your understanding and ability to solve problems pro-actively. On both a personal and professional level SA:SI Strategic Intervention Coaching can help you with:-</p>
        <div className={styles.gridMe}>
          <div className="blockTextInner">
            <h4>Low Self Esteem and Confidence:</h4>
      Not feeling good enough, fear of failure, lost your spark, getting older, losing your looks,
      losing your ‘self’, not living your best life, not being your best you!
    </div>
          <div className="blockTextInner">
            <h4>Health Issues: </h4>
      Weight, lack of exercise, diet, dis-ease, illness, phobias, hair loss, ageing, loss of libido
      </div>
          <div className="blockTextInner">
            <h4>Grief: </h4>
      Coming to terms with the loss of a loved one, heartbreak , denial, guilt,  feelings of failure,
      anger, blame, depression, inability to move on,  not being able to get over the loss of a loved
      one, addictive behaviour to blank out the pain, using the grief to push people away
    </div>
          <div className="blockTextInner">
            <h4>Relationship Issues :</h4>
      Lack of trust, lost intimacy, lack of respect, bickering and arguing, loss of communication,
      jealousy, going in different directions, financial disagreements, a growing distance between you
      and your partner, not being able to get over a relationship which has ended, betrayal, can’t let
       go, abuse, mistrust,  childhood abuse issues, unable to ‘move on’
      </div>
          <div className="blockTextInner">
            <h4>Life Changes: </h4>
      Having a family, parenting issues, children leaving home, divorce, fear of ageing, menopause,
      promotion, career advancement, redundancy, retirement, fear of mortality, loss of significance.
    </div>
          <div className="blockTextInner">
            <h4>Spirituality: </h4>
      You don’t feel fulfilled, you don’t feel ‘connected,’ there must be more to life, searching
      for something.
      </div>
        </div>
      </div>
    </div>
  );
}