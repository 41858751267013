import React from 'react';
import img from '../../assets/images/relationships.jpg';
import styles from './relationshipCounselling.module.scss';

export default function RelationshipCounselling() {
  return (
    <div className={styles.relationShipPage}>
      <div className="pageTitle">
        <div className="siteMaxWidth">
          <h1>Relationship Counselling</h1>
        </div>
      </div>
      <div className={`siteMaxWidth pageContainer`}>
        <div className={styles.imageHolder}>
          <img className={styles.banner} src={img} alt="Relationships"></img>
        </div>
        <div className={`bulletList ${styles.list}`}>
          <h3>SA:SI can help if you are seeking help with:</h3>
          <ul>
            <li>Sense of something missing/lost in the relationship</li>
            <li>Lack of communication with partner</li>
            <li>Escalating arguments/ circular arguments, distressing patterns of conflict</li>
            <li>Loss of intimacy and sexual desire</li>
            <li>Impact of professional life, a new job, promotion, redundancy, retirement</li>
            <li>Parenting issues, either with your own children or becoming/being a step parent</li>
            <li>Coping with Aging parents</li>
            <li>Adapting to marriage or becoming parents</li>
            <li>Impact of post natal depression on the relationship</li>
            <li>Feeling badly treated or let down by partner</li>
            <li>Repeating destructive relationship patterns</li>
            <li>Infidelity</li>
            <li>Commitment difficulties</li>
            <li>Divorce and separation</li>
            <li>Coping with a break up</li>
          </ul>
        </div>
        <div className={`blockQuote ${styles.quote}`}><span className="quote"></span>If we always acted the way we acted at the beginning of a relationship, there would be no end!</div><p className={styles.topPara}>Relationships are an essential part of our lives, from family and friends to partners and spouses. Indeed, one of the 6 human needs that everyone shares is the need for Love and Connection which drives us to develop intimate and intense relationships with a few select individuals and can bring tremendous joy and happiness. Difficulties in relationships are one of the most common causes of unhappiness, and distress. Sometimes, even though the love is still very much alive the relationship is failing.  If we don’t know what we are doing wrong, we cannot put it right. Relationship counselling through strategic Intervention gives you the understanding, clarity and tools needed to regenerate levels of trust, happiness, passion and love more profoundly than you ever thought possible.
</p>
        
        <h4>COUPLE RELATIONSHIP COUNSELLING</h4>
        <p>SI:SA strategic intervention offers couples the opportunity to air hurt and angry feelings in a safe and controlled setting. Relationships can become "gridlocked", repeating destructive patterns. The insights offered in relationship therapy can free couples from a negative dynamic and establish new, positive modes of relating. Human Needs Psychology gives both partners the strategies to think about their relationship with empathy and gain insight into both their own and their partner's needs, feelings and behaviour. Relationship counselling with both partners present can bring invaluable insight into deep-seated reasons behind repeated patterns, frustrations or escalating or circular arguments.  The 1 – 2 hour consultations can take place on a weekly basis, or we offer weekend or mid-week accelerator retreats in Shaldon, Devon, which removes you from everyday surroundings and interruptions, allowing you both to fully focus on getting your relationship back on track.  Please call or e-mail for further information.</p>
        <h4>INDIVIDUAL WORK</h4>
        <p>Many people choose to seek relationship therapy without a partner. Individual relationship therapy is often sought by those experiencing difficulties in coping with a break up, or who are undecided whether to continue in a relationship. It can be useful for those who want to explore the absence of lasting intimate relationships. Relationship therapy can help you to discover how you relate to others and why you are drawn to particular people.</p>
        <p>In many instances, just one of the partners having S.I counselling will give them the insight and clarity into how their own behaviour has contributed to the current relationship issues, and by consciously understanding and changing the way they are, can positively impact and change the mind of their partner.</p>
      </div>
    </div>
  );
}